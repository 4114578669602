import {Container} from '../../../common';
import NavbarLinks from '../NavbarLinks';
import React from 'react';
import {Wrapper} from './styles';

const Navbar = () => (
    <Wrapper as={Container} data-cy="navbar">
        <a href="#">
            <img src="/my-logo-wide.png"/>
        </a>
        <NavbarLinks desktop/>
    </Wrapper>
);

export default Navbar;
