import React, {useContext, useRef} from 'react';
import {ApplicationContext} from "../../../../../context/applicationContext";
import "./ProjectDetails.css";
import AnimatedContainer from "../../../../../component/animated-container/AnimatedContainer";
import AnimatedImage from "../../../../../component/animated-image/AnimatedImage";
import AnimatedImageSlider from "../../../../../component/animated-image-slider/AnimatedImageSlider";
import QuoteContainer from "../../../../../component/quote-container/QuoteContainer";
import useWindowDimensions from "../../../../../hooks/useWindowDimentions";
import {useNavigate} from "react-router-dom";
import {themeColor} from "../../../../../data/config";

function MathMasrShowCase({currentValue}) {
    const {setCurrentFace, setCubeBackgroundColor} = useContext(ApplicationContext);
    const {width} = useWindowDimensions();
    const myRef = useRef(null)
    const myRef2 = useRef(null)
    const myRef3 = useRef(null)
    const myRef4 = useRef(null)
    const myRef5 = useRef(null)
    const myRef6 = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({behavior: "smooth"})
    const executeScroll2 = () => myRef2.current.scrollIntoView({behavior: "smooth"})
    const executeScroll3 = () => myRef3.current.scrollIntoView({behavior: "smooth"})
    const executeScroll4 = () => myRef4.current.scrollIntoView({behavior: "smooth"})
    const executeScroll5 = () => myRef5.current.scrollIntoView({behavior: "smooth"})
    const executeScroll6 = () => myRef6.current.scrollIntoView({behavior: "smooth"})
    const navigate = useNavigate();

    return (
        <div className="math-masr-details-main-container">
            <button style={{
                position: width > 768 ? "fixed" : "absolute",
                top: "2vh",
                right: "5%",
                transform: "rotate(180deg)",
                zIndex: 600,
                borderColor: "#fff",
                color: "#fff"
            }}
                    className="link link--leda" data-text="➜"
                    onClick={(e) => {
                        setCubeBackgroundColor(themeColor)
                        setCurrentFace("front")
                        navigate("/projects")
                    }}>
                <span style={{padding: "0 2px", color: "#fff"}}>➜</span>
            </button>
            <ul className="math-masr-details-nav">
                <li className="math-masr-details-nav-li">
                    <button className="math-masr-details-nav-button" onClick={executeScroll2}>
                        <span>01</span>Student System
                    </button>
                </li>


                <li className="math-masr-details-nav-li">
                    <button className="math-masr-details-nav-button" onClick={executeScroll5}>
                        <span>02</span>User Dashboard
                    </button>
                </li>
                <li className="math-masr-details-nav-li">
                    <button className="math-masr-details-nav-button" onClick={executeScroll6}>
                        <span>03</span>Courses Page
                    </button>
                </li>
                <li className="math-masr-details-nav-li">
                    <button className="math-masr-details-nav-button" onClick={executeScroll3}>
                        <span>04</span>Buy A Course
                    </button>
                </li>
                <li className="math-masr-details-nav-li">
                    <button className="math-masr-details-nav-button" onClick={executeScroll}>
                        <span>05</span>Admin Panel
                    </button>
                </li>
                <li className="math-masr-details-nav-li">
                    <button className="math-masr-details-nav-button" onClick={executeScroll4}>
                        <span>06</span>Add Exam
                    </button>
                </li>
            </ul>

            <AnimatedImage
                src="/portfolio/math-masr/cover.webp" title={"Math\nMasr"} colorTheme="#fff"
                currentValue={currentValue}/>

            <AnimatedContainer
                textLeft1="Math Masr"
                textLeft2="It is an educational system created for teachers who teach math in Egypt's high school education centers."
                textRight1="A full-featured educational system with desktop notifications, full admin administration, deep data analysis, exams system using latex for math formula, Vimeo video hosting, ranking system, attendance, and more."
                textRight2="Morcos came to us with great expectations for the development and implementation of their new educational system."
                isText1Bold
                link="https://mathmasr.com/"
                title="Math Masr"
                type="Full Stack Developer"
                isThirdContainer
                themeColor="#fff"
            />
            <AnimatedImageSlider
                myRef={myRef2}
                images={[{original: "/portfolio/math-masr/desktop-4.webp", alt: "Math Masr"}]}
                title="Student System:"
            />

            <AnimatedContainer
                textLeft1="The teacher was giving private lessons in different locations."
                textLeft2="during the covid he wanted a full educational system,"
                textRight1="The system was developed so that students may view lectures, complete homework, and take quizzes and exams."
                textRight2="100% controlled by the Admin Panel"
                isText1Bold
            />

            <AnimatedImageSlider
                images={[{original: "/portfolio/math-masr/desktop-1.webp"}, {original: "/portfolio/math-masr/user_profile.webp"}]}
                title="User System"
                text={["*Dashboard", "*Personalization"]} myRef={myRef5}/>

            <AnimatedContainer
                style={{margin: "2% auto"}}
                textLeft1="What was most important was the user experience,"
                textRight1="which was designed to be as easy as possible."
                isText1Bold
            />

            <AnimatedImageSlider
                images={[
                    {original: "/portfolio/math-masr/user_courses.webp"},
                    {original: "/portfolio/math-masr/user_units.webp"},
                    {original: "/portfolio/math-masr/user_lesson.webp"},
                    {original: "/portfolio/math-masr/user_Video.webp"},
                    {original: "/portfolio/math-masr/user_quiz.webp"},
                ]}
                title="Courses Page"
                text={["— Courses",
                    "— Units",
                    "— Lesson",
                    "— Lecture Vid",
                    "— Quiz"]} myRef={myRef6}/>

            <AnimatedImageSlider
                images={[
                    {original: "/portfolio/math-masr/desktop-2.webp"},
                    {original: "/portfolio/math-masr/desktop-3.webp"},
                ]}
                title="General Exam"
                text={["— The Exam",
                    "— Light Theme",
                    "",
                    "These Exams are open to everyone,",
                    "Students are not required to","purchase any lessons","in order to take this exam."]}/>

            <QuoteContainer
                colorTheme="#fff" person="Morcos, Math Instructor"
                text1="Simply put Mostafa can take a good design and turn it into something great."
                text2="He is one of those unique people that can think both creatively and analytically to make projects that are internet famous and built like tanks."
                text3="More importantly he is a great guy with a good heart that wont let you down."/>

            <AnimatedImageSlider
                style={{margin: "2% auto"}} myRef={myRef3}
                images={[{original: "/portfolio/math-masr/user_buy_course.mp4", alt: "buy course"}]}
                title="Buy A Course"
                text={["A simple method for the user to purchase courses"]}
                isVideo/>


            <AnimatedImageSlider
                images={[{original: "/portfolio/math-masr/admin_users_table.webp"},]} isText={false}
                style={{margin: "2% auto"}} myRef={myRef}/>

            <AnimatedContainer
                style={{marginTop: "2%"}}
                textLeft1="Admin Panel"
                textLeft2="User system containing data, attendance system, exams, quizzes, student answers, and more"
                textRight1="The structure system consists of a tree of grade->course->unit->lesson->lesson data."
                textRight2="The content area manages the course's files and videos, places quizzes and exams, and controls and displays notifications and the calendar on the user page."
                isText1Bold/>

            <AnimatedImageSlider
                images={[
                    {original: "/portfolio/math-masr/admin-users-time-spent.webp"},
                    {original: "/portfolio/math-masr/admin_users_visits.webp"},
                    {original: "/portfolio/math-masr/admin_users_attendance.webp"},
                    {original: "/portfolio/math-masr/admin_user_exam.webp"},
                    {original: "/portfolio/math-masr/admin_show_user_exam.webp"},
                ]}
                title="Sample Data"
                text={["— Time Spent",
                    "— User Visits",
                    "(for analysis and advertising", "purposes)",
                    "",
                    "— User Attendance",
                    "— User Answers",]}/>

            <AnimatedImageSlider
                style={{margin: "1% auto"}}
                images={[
                    {original: "/portfolio/math-masr/admin_lecture_dates.webp"},
                    {original: "/portfolio/math-masr/admin_user_lecture_data.webp"},
                ]} title="Course Attendance"
                text={["This Section manages", " course attendance by ", "scanning the QR code or the user barcode."]}/>

            <AnimatedImageSlider
                style={{margin: "1% auto"}}
                images={[
                    {original: "/portfolio/math-masr/admin_skeleton_grade.webp"},
                    {original: "/portfolio/math-masr/admin_skeleton_courses.webp"},
                    {original: "/portfolio/math-masr/admin_skeleton_units.webp"},
                    {original: "/portfolio/math-masr/admin_skeleton_lessons.webp"},
                    {original: "/portfolio/math-masr/admin_skeleton_lessons_filtered.webp"},
                    {original: "/portfolio/math-masr/admin_courses_dark.webp"},
                    {original: "/portfolio/math-masr/admin_courses_light.webp"},
                ]} title="System Structure"
                text={["— Grade",
                    "— Course",
                    "— Unit",
                    "— Lessons",
                    "— Filtered Lessons",
                    "— Filtered Courses",
                    "— Courses Light",
                ]}/>
            <AnimatedContainer
                textLeft1="System Structure"
                textLeft2="The system is designed for teachers who teach in schools, thus the structure contains four differing levels: grades, courses, units, and lessons. The student can only pay to purchase the lesson, which may include multiple videos, homeworks in different forms, homework answers, files (for example, pictures of the board), and quizzes."
                textRight1="You can see how it is used in the user system by viewing"
                textRight2="Grade levels range from 7 to 12. Courses are study fields that include Calculus, Dynamics, Statics, and so on. Units vary depending on the course and the educational system, but they are usually numbers (unit 1, unit 2, etc)."
                isText1Bold
                title="Math Masr"
                themeColor="#fff"
                buttonTxt="this video"
                handleOnClick={executeScroll3}
            />

            <AnimatedImageSlider
                style={{margin: "1% auto"}}
                images={[
                    {original: "/portfolio/math-masr/admin_skeleton_questions.webp"},
                    {original: "/portfolio/math-masr/admin_skeleton_question_source.webp"},
                    {original: "/portfolio/math-masr/admin_skeleton_exams.webp"},
                    {original: "/portfolio/math-masr/admin_exel_exam_questions.webp"},

                ]} title="Exam System"
                text={["— Questions",
                    "— Sources",
                    "— Exams",
                    "_ Exel Questions"
                ]}/>

            <AnimatedContainer
                style={{marginTop: "2%"}}
                textLeft1="Exams System"
                textRight1="In the process of creating an exam, the teacher must define some details about it such as the number of questions, duration, grade, and so on, As shown in"
                textRight2="specify the question body, choices, right answer (may be a choice or steps), difficulty, image (or video), grade, course, unit, lesson, and source"
                buttonTxt="this video."
                themeColor="#fff"
                handleOnClick={executeScroll4}
                isText1Bold/>


            <AnimatedImageSlider
                style={{margin: "2% auto"}}
                images={[{original: "/portfolio/math-masr/admin_add_exam.mp4", alt: "buy course"}]}
                title="Add Exam"
                text={["A simple method for the teacher to add exam"]}
                myRef={myRef4}
                isVideo/>

            <AnimatedImageSlider
                style={{margin: "1% auto"}}
                images={[
                    {original: "/portfolio/math-masr/admin_skeleton_exam_submission.webp"},
                    {original: "/portfolio/math-masr/admin_skeleton_exam_group.webp"},
                    {original: "/portfolio/math-masr/admin_skeleton_exam_questions.webp"},
                ]} title="Exam & HW Details"
                text={["— Submissions",
                    "— Groups",
                    "— Unit",
                    "— Questions"]}/>

            <AnimatedImageSlider
                style={{margin: "1% auto"}}
                images={[
                    {original: "/portfolio/math-masr/admin_content_notifications.webp"},
                    {original: "/portfolio/math-masr/admin_content_calender.webp"},
                ]} title="User Alert"
                text={["— Notification",
                    "— Calender",
                    "",
                    "The sections where", "the teacher can direct", "whether some or all", "of the students be notified.",
                "It will be displayed","in the user dashboard","as shown"]}
                buttonTxt="here."
                themeColor="#fff"
                handleOnClick={executeScroll5}
            />

            <AnimatedImageSlider
                style={{margin: "1% auto"}}
                images={[
                    {original: "/portfolio/math-masr/admin_content_videos.webp"},
                    {original: "/portfolio/math-masr/admin_content_videos_files.webp"},
                ]} title="Course Videos & Files"
                text={["— Videos",
                    "— Files",
                    "",
                    "Every video or file","is used someplace","as shown","in"]}
                buttonTxt="this photo 3/5."
                themeColor="#fff"
                handleOnClick={executeScroll6}
            />

            <div className="math-masr-details-next-project">
                <h2 className="math-masr-details-next-project-h2">next project</h2>
                <span className="math-masr-details-next-project-span" onClick={(e) => {
                    setCurrentFace("right");
                    setCubeBackgroundColor("#fff")
                    navigate("/projects/tropical")
                }}>Tropical<br/>For<br/>Landscape</span>
            </div>
        </div>
    );
}

export default MathMasrShowCase;