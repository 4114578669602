import './fonts.css';

import {Footer, Particles1} from '../../theme';

import {Global} from './styles';
import {LayoutWrapper} from './layout-wrapper';
import PropTypes from 'prop-types';
import React from 'react';
import {useInView} from "react-intersection-observer";
import DesktopSideElement from "../../theme/Footer/component/DesktopSideElement";

export const Layout = ({children}) => {
    const {ref, inView} = useInView({threshold: 0.1, triggerOnce: false});
    return (
        <>
            <Global/>
            <Particles1/>
            <LayoutWrapper>
                {children}
            </LayoutWrapper>
            <div ref={ref}>
                <Footer/>
            </div>
            <DesktopSideElement inView={inView} />
            <DesktopSideElement inView={inView} isRight={false} isSocial/>
        </>
    );
}

Layout.propTypes = {
    children: PropTypes.array
};
