import React, {useContext, useEffect, useState} from 'react';
import "./AnimatedImage.css"
import {useInView} from "react-intersection-observer";
import {ApplicationContext} from "../../context/applicationContext";

function AnimatedImage({src, title, currentValue, colorTheme = "#000"}) {
    const [isOpen, setIsOpen] = useState(false);
    const [delayedInView, setDelayedInView] = useState(false);
    const {currentFace, setCurrentFace} = useContext(ApplicationContext);


    useEffect(() => {
        setTimeout(() => {
            if (currentFace === currentValue) {
                setIsOpen(true)
                setTimeout(() => {
                    setDelayedInView(true);
                }, 300);
            } else {
                setIsOpen(false)
                setDelayedInView(false)
            }
        }, 600);
    }, [currentFace]);
    return (
        <div
            className={`animated-image-container ${isOpen ? 'fade-img-in' : 'fade-img-out'}`}>
            <h1 style={{webkitTextStroke: `2px ${colorTheme}`}}
                className={`animated-image-text  ${delayedInView ? 'slide-right-in' : 'slide-right-out'}`}>{title}</h1>

            <img className="animated-image-image" src={src} alt="mainImage"/>
        </div>
    );
}

export default AnimatedImage;