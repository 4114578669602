import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {ApplicationProvider} from "./context/applicationContext";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import MathMasrShowCase from "./landing/Projects/modal/portoflio/math-masr-details/MathMasrShowCase";
import TropicalShowCase from "./landing/Projects/modal/portoflio/tropical-details/TropicalShowCase";
import FixedSizeModal from "./landing/Projects/modal/FixedSizeModal";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {
                path: "projects/math-masr",
                element: <MathMasrShowCase/>
            },
            {
                path: "projects/tropical",
                element: <TropicalShowCase/>
            },
            {
                path: "projects",
                element: <FixedSizeModal/>
            }
        ]
    },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ApplicationProvider>
            <RouterProvider router={router}/>
        </ApplicationProvider>
    </React.StrictMode>
);
