import React, {useRef, useState} from 'react';
import "./AnimatedImageSlider.css"
import {useInView} from "react-intersection-observer";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination, Thumbs} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";

function AnimatedImageSlider({
                                 images = [],
                                 title = "",
                                 text = [],
                                 isVideo = false,
                                 isText = true,
                                 style,
                                 myRef = null,
                                 buttonTxt = null,
                                 themeColor = "#000",
                                 handleOnClick,
                             }) {
    const {ref, inView} = useInView({threshold: 0.1, triggerOnce: false});
    const swiperRef = useRef();
    const [slideNum, setSlideNum] = useState(1);

    return (
        <div ref={myRef} style={style} className="animated-image-slider">
            {
                isVideo ?
                    <video controls autoPlay muted loop style={{width: !isText && "100%"}}>
                        <source src={images[0].original} type="video/mp4"/>
                    </video>
                    :
                    images.length > 1 ?
                        <Swiper
                            className="mySwiper" style={{
                            width: "100%",
                        }} slidesPerView={1} speed={1000} pagination={true} modules={[Pagination, Navigation, Thumbs]}
                            watchSlidesProgress
                            onBeforeInit={(swiper) => {
                                swiperRef.current = swiper;
                            }}
                            onSlideChange={(swiper) => setSlideNum(swiper.activeIndex + 1)}>
                            {images.map((item, index) => {
                                return (
                                    <SwiperSlide id={index}><img style={{
                                        width: "100%",
                                        objectFit: "cover",
                                    }} alt={""} src={item.original}/></SwiperSlide>
                                )
                            })}
                        </Swiper>
                        :
                        <img src={images[0].original} alt={images[0].alt} style={{width: !isText && "100%"}}/>

            }
            {
                isText &&
                <div ref={ref} className={`animated-image-slider-text ${inView ? 'page-in' : 'page-out'}`}>
                    {
                        images.length > 1 &&
                        <div className="animated-image-slider-button-container">
                            <p onClick={() => swiperRef.current?.slidePrev()}>←</p>
                            <p>{slideNum} / {images.length}</p>
                            <p onClick={() => swiperRef.current?.slideNext()}>→</p>
                        </div>
                    }

                    <h6><strong>{title}</strong></h6>
                    <p>
                        {
                            text.length > 1 ?
                                text.map((item, index) => {
                                    if (index === text.length - 1) {
                                        return <>{item}
                                            <button className="animated-container-btn" style={{color: themeColor}}
                                                    onClick={handleOnClick}>{buttonTxt}</button>
                                            <br/></>

                                    } else {
                                        return <>{item} <br/></>
                                    }
                                })
                                : text[0]}</p>
                </div>
            }

        </div>
    );
}

export default AnimatedImageSlider;