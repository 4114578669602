import React, {useState, useRef, useEffect} from "react";
import "./TabContainer.css";

const tabs = [
    {
        id: 1,
        title: "Alpha Matar",
        link: "https://www.alphamatar.com/",
        role: " Software Developer",
        date: "MAY 2019 - DEC 2022",
        location: " Cairo, Egypt",
        experience: [
            {mainTxt: "Directed a team of five in the development of complex ERP systems with multiple versions."},
            {mainTxt: "Wrote modern, performant, and robust code for a diverse array of client and internal projects"},
            {mainTxt: "Worked with a number of languages, frameworks, and content management systems, including JavaScript, TypeScript, React, Vue, Node.js, Laravel, Kotlin, and others."},
            {mainTxt: "Communicated and collaborated with multi-disciplinary teams of engineers, designers"},
        ]
    },
    {
        id: 2,
        title: "Mwk3",
        link: "https://www.mwk3.com/",
        role: "Software Developer",
        date: "AUG 2017 - MAY 2019",
        location: " Dokki, Giza, Egypt",
        experience: [
            {
                mainTxt: "Administered the software development process to clients"
            },
            {
                mainTxt: "Created and maintained code for the client's websites and software products utilizing Java (android studio), React, HTML, CSS, javascript, PHP, and SQL."
            },
            {
                mainTxt: "Managed client website promotion (SEO)"
            },
            {
                mainTxt: "Developed and maintained all the  company's software products which are",
                list2: [
                    "SiteBuilder (Coded with asp.net)",
                    "Management system (coded with React, php & SQL)",
                    "Sales System (coded with VueJs, Slim Framework & SQL)"]
            },
        ]
    },
    {
        id: 3,
        title: "RadiX CIC",
        link: "https://www.facebook.com/RadiXCIC",
        role: "Software Developer",
        date: "APR 2021 - NOV 2021",
        location: "New Cairo , Egypt",
        experience: [
            {
                mainTxt: "I was a member of the Radix CIC club's technical team in the 𝙈𝘼𝙏𝙀 𝙀𝙜𝙮𝙥𝙩 𝙍𝙚𝙜𝙞𝙤𝙣𝙖𝙡 𝙍𝙊𝙑 𝘾𝙤𝙢𝙥𝙚𝙩𝙞𝙩𝙞𝙤𝙣",
                list2: ["ROV: Remotely Operated underwater Vehicle"]
            },
            {
                mainTxt: "Creating the user interface for the robot"
            },
            {
                mainTxt: "Maintaining the robot functionalities",
                list2: ["Image Processing", "Color Detecting", "Task Operations"]
            }
        ]
    },
    {
        id: 4,
        title: "PieceOfCode",
        link: "PieceOfCode",
        role: "Instructor & Teacher",
        date: "AUG 2021 - MAY 2022",
        location: "New Cairo , Egypt",
        experience: [
            {
                mainTxt: "I gave some lectures starting from beginners to intermediate in each of the following",
                list2: ["Java", "FrontEnd: HTML CSS Javascript", "C++"]
            }
        ]
    }
];

export default function TabsContainer() {
    const [activeTab, setActiveTab] = useState(tabs[0].id);
    const [linePosition, setLinePosition] = useState(1);
    const lineRef = useRef(null);

    const handleTabClick = (tabId, index) => {
        setActiveTab(tabId);
        setLinePosition(index + 1);
    };

    useEffect(() => {
        const line = lineRef.current;

        if (linePosition === -1) {
            const tab = line.parentNode.children[0];
            const leftOffset = tab.offsetLeft;
            const topOffset = tab.offsetTop;
            line.style.transform = `translate(${leftOffset}px, ${topOffset}px)`;
        } else {
            const tab = line.parentNode.children[linePosition];
            const leftOffset = tab.offsetLeft;
            const topOffset = tab.offsetTop;
            line.style.transform = `translate(${leftOffset}px, ${topOffset}px)`;
        }
    }, [linePosition]);

    return (
        <div className="tab-container-container">
            <div className="tab-container-tabs">
                <div className="tab-container-line" ref={lineRef}/>
                {tabs.map((tab, index) => (
                    <div
                        key={tab.id}
                        className={`tab-container-tab ${activeTab === tab.id ? "active" : ""}`}
                        onClick={() => handleTabClick(tab.id, index)}
                    >
                        {tab.title}
                    </div>
                ))}
            </div>
            <div className="tab-container-content">
                <div>
                    <h3>{tabs[activeTab - 1].role}
                        <span style={{color: "var(--primary)"}}>
                        &ensp;@&nbsp;
                            <a href={tabs[activeTab - 1].link} target="_blank" className="link link--elara"
                               style={{color: "var(--primary)"}}>
                            <span>{tabs[activeTab - 1].title}</span>
                        </a>
                            </span>
                    </h3>
                    <p>{tabs[activeTab - 1].date} in {tabs[activeTab - 1].location}</p>

                    {
                        tabs[activeTab - 1].experience &&
                        <ul className="tab-container-ul">
                            {
                                tabs[activeTab - 1].experience.map((item, index) => {
                                    return (
                                        <li id={`tab-container-${index}`} className="tab-container-li">
                                            {item.mainTxt}
                                            {
                                                item.list2 &&
                                                <ul className="tab-container-ul">
                                                    {
                                                        item.list2.map((list2Item, index) => {
                                                            return (
                                                                <li id={`tab-container-list2-${index}`}
                                                                    className="tab-container-li2">
                                                                    {list2Item}
                                                                </li>
                                                            );
                                                        })
                                                    }
                                                </ul>
                                            }
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    }
                </div>

            </div>
        </div>
    );
}