module.exports = {
	defaultTitle: 'Meet Mostafa Matar',
	logo: 'https://matarmusic.files.wordpress.com/2013/02/image.jpg?w=584',
	url: 'https://mostafamatar.com',
	author: 'Mostafa Matar',
	legalName: 'Mostafa Matar',
	defaultDescription: 'I’m Mostafa Matar, and I’m a Software Engineer.',
	themeColor: '#00B7E3',
	themeColorDark: '#0080B5',
	backgroundColor: '#00B7E3',
	socialLinks: {
		github: 'https://github.com/mostafamatar2002',
		linkedin: 'https://www.linkedin.com/in/mostafamatar2002/'
	},
	social: {
		Instagram: '@mvstaffaa'
	},
	address: {
		city: 'New Brighton',
		region: 'Midwest',
		country: 'USA',
		zipCode: '55112'
	},
	foundingDate: '2019',
	googleAnalyticsID: 'G-00000000',
	reCaptchaKey: '6LdEwL4UAAAAAMV4qvWijJFZ-Q2AOosMUUjQo4vp'
};
