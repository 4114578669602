import React from 'react';
import "./QuoteContainer.css"
import {useInView} from "react-intersection-observer";

function QuoteContainer({text1 = "", text2 = "", text3 = "", person, colorTheme = "#000"}) {

    const {ref, inView} = useInView({triggerOnce: false});
    return (
        <div className="quote-container">
            <span className="quote-container-quote-left" style={{color: colorTheme}}>“</span>
            <blockquote style={{color: colorTheme}} ref={ref}
                        className={`quote-container-blockquote ${inView ? 'page-in' : 'page-out'}`}>
                <p>{text1}</p>
                <p>{text2}</p>
                <p>{text3}</p>
            </blockquote>
            <p className={`quote-container-attribution ${inView ? 'page-in' : 'page-out'}`}
               style={{color: colorTheme}}>{person}</p>
        </div>
    );
}

export default QuoteContainer;