import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    padding: 1.5rem 0;
    display: flex;
    justify-content: space-around;
    z-index: 500;
    right: 0;
    top: 0;
    position: fixed;

    a:first-child {
        margin-top: -13px;
    }
    a {
        color: #212121;

        img {
            height: 40px;
        }
    }

    @media (max-width: 960px) {
        a:first-child {
            display: none;
        }
    }
`;
