import React from 'react';
import '../Footer.css'
import social from "../social.json";
import useWindowDimensions from "../../../hooks/useWindowDimentions";

function DesktopSideElement({inView, isRight = true, isSocial = false}) {

    const {width} = useWindowDimensions();
    return (
        <div className={`desktop-side-element-container ${inView ? 'fade-out' : 'fade-in'}`}
             style={{
                 right: isRight ? width > 1380 ? "40px" : "20px" : "unset",
                 left: !isRight ? width > 1380 ? "40px" : "20px" : "unset"
             }}>

            {
                isSocial ?
                    <div className="desktop-side-element-icon-container">
                        {social.map(({id, name, link, icon}) => (
                            <a key={id} href={link} target="_blank" rel="noopener noreferrer"
                               aria-label={`follow me on ${name}`}>
                                <img className="desktop-side-element-icon-container-img" width="24" src={icon}
                                     alt={name}/>
                            </a>
                        ))}
                    </div>
                    :
                    <a href="mailto:me@mostafamatar.com">me@mostafamatar.com</a>
            }
        </div>
    );
}

export default DesktopSideElement;