import {Details, Flex, Links, Wrapper} from './styles';

import {Container} from '../../common';
import React from 'react';
import social from './social.json';
import './Footer.css'

export const Footer = () => (
	<Wrapper>
		<Flex as={Container}>
			<Details>
				<h2>Mostafa Matar</h2>
				<span>
					© All rights are reserved | {new Date().getFullYear()} | Check out the projects
				</span>
			</Details>
			<Links>
				{social.map(({id, name, link, icon}) => (
					<a key={id} href={link} target="_blank" rel="noopener noreferrer" aria-label={`follow me on ${name}`}
					className="footer-icons">
						<img width="24" src={icon} alt={name}/>
					</a>
				))}
			</Links>
		</Flex>
	</Wrapper>
);
