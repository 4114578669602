import {Button, Container} from '../../common';
import {Detail, IntroWrapper, Thumbnail, Wrapper} from './styles';

import {Header} from '../../theme';
import React from 'react';
import Terminal from './Terminal';

function openResumeTab() {
    window.open(
        'https://docs.google.com/document/d/1BTIQmSDsAnWTur7a8JF0JJjaHC6FI98AagwOttNO1x4/edit?usp=sharing',
        '_blank'
    );
}

export const Intro = () => (
    <Wrapper>
        <Header/>
        <IntroWrapper as={Container}>
            <Detail className="content">
                <h1>Hi there!</h1>
                <Terminal actions={[
                    {command: '<span class=\'command\'>echo</span> $WHO_AM_I', response: 'I’m Mostafa Matar.'},
                    {command: '<span class=\'command\'>echo</span> $WHAT_I_DO', response: 'I’m a Software Engineer.'},
                    {
                        command: '<span class=\'command\'>echo</span> $(<span class=\'command\'>random_fact_about_me</span>)',
                        response: 'I like to resolve design problems, create smart user interface and imagine useful interaction, developing rich web experiences & web applications.'
                    },
                    {
                        command: '<span class=\'command\'>echo</span> $(<span class=\'command\'>random_specialty</span>)',
                        response: 'from CRM/ERP to E-commerce to any custom System\n' +
                            'passion and innovation fill my heart.'
                    },
                    {
                        command: '\`<span class=\'command\'>echo</span> $(<span class=\'command\'>random_specialty</span>)\`',
                        response: 'I specialize in Development Tooling.'
                    },
                    {
                        command: '\`<span class=\'command\'>echo</span> $(<span class=\'command\'>random_specialty</span>)\`',
                        response: 'I specialize in CI/CD Infrastructure.'
                    },
                    {
                        command: '\`<span class=\'command\'>echo</span> $(<span class=\'command\'>random_specialty</span>)\`',
                        response: 'I specialize in Realtime Dashboards.'
                    }
                ]}/>
                <Button id="resume" onClick={() => openResumeTab()}>
                    Resume
                </Button>
            </Detail>
            <Thumbnail className="thumbnail">
                <img src="/illustrations/dev.webp" quality={100} alt="Man on laptop next to git tree."/>
            </Thumbnail>
        </IntroWrapper>
    </Wrapper>
);
