import AnchorLink from 'react-anchor-link-smooth-scroll';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {Wrapper} from './styles';
import {motion, useAnimation} from "framer-motion";
import useWindowDimensions from "../../../hooks/useWindowDimentions";

const NavbarLinks = ({desktop}) => {

    const [shouldAnimate, setShouldAnimate] = useState(false);

    const ulRef = useRef(null);
    const controls = useAnimation();

    const variants = {
        hidden: {opacity: 0, y: -50},
        visible: {opacity: 1, y: 0},
    };

    useEffect(() => {
        setShouldAnimate(true);
    }, []);

    const items = [
        {
            id: 1,
            title: "Projects",
            href: "#projects"
        },
        {
            id: 2,
            title: "Experience",
            href: "#experience"
        },
        {
            id: 3,
            title: "Contact",
            href: "#contact"
        },
    ]

    const {width} = useWindowDimensions();
    return (
        <Wrapper desktop={desktop}>
            <ul ref={ulRef} style={{display: "flex", listStyle: "none",flexDirection: width < 768 && "column"}}>
                {items.map((item, index) => (
                    <motion.li
                        key={item.id}
                        variants={variants}
                        initial="hidden"
                        animate={shouldAnimate ? "visible" : "hidden"}
                        transition={{delay: index * 0.2, duration: 0.5}}
                        onAnimationStart={() => controls.start("visible")}
                    >
                        <AnchorLink data-cy="projects-link" href={item.href}><span>0{item.id}.</span> {item.title}
                        </AnchorLink>
                    </motion.li>
                ))}
            </ul>
        </Wrapper>
    );
}

NavbarLinks.propTypes = {
    desktop: PropTypes.bool
};
export default NavbarLinks;
