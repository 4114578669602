import styled from 'styled-components';

export const Button = styled.button`
	cursor: pointer;
	border-radius: 3px;
	padding: 0.7rem 2.5rem;
	border: none;
	-webkit-appearance: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #fff;
	background: var(--primary);


	&:focus {
		outline: none;
	}
	&:active {
    	background-color: #0900b1;
	}
	&:disabled {
		background: gray;
	}

	&:last-child{
		margin-top: 2%;
	}
	${({secondary}) =>
		secondary &&
		`
		background: var(--primary);
	`}
`;
