import {Card, Container} from '../../common';
import {
	Content,
	ExperienceWrapper,
	Row,
	Thumbnail,
	Wrapper,
	Column
} from './styles';

import React from 'react';
import TabsContainer from "./tabs-container/TabsContainer";

export const Experience = () => {


	return (
		<Wrapper id="experience">
			<h2>Experience</h2>
			<ExperienceWrapper as={Container}>
				<Row>
					<Thumbnail>
						<img src="/illustrations/Experience.svg" alt="Man presenting analytics"/>
					</Thumbnail>
					<Column>
						<TabsContainer />
					</Column>
				</Row>
			</ExperienceWrapper>
		</Wrapper>
	);
};
