import React from 'react';
import {Layout, Seo} from "./common";
import {Contact, Intro, Projects, Experience, Stars} from "./landing";
import "./LinkHover.css";

function App() {
    return (
        <Layout>
            <Seo/>
            <Intro/>
            <Projects/>
            <Stars/>
            <Experience/>
            <Contact/>
        </Layout>
    );
}

export default App;
