import React, {createContext, useState} from "react";
import {themeColor} from "../data/config";

export const ApplicationContext = createContext();


export function ApplicationProvider(props) {


	const [currentFace, setCurrentFace] = useState("front");
	const [cubeBackgroundColor, setCubeBackgroundColor] = useState(themeColor);

	return (
		<ApplicationContext.Provider value={{
			currentFace,
			setCurrentFace,
			cubeBackgroundColor,
			setCubeBackgroundColor
		}}>
			{props.children}
		</ApplicationContext.Provider>
	);

}
