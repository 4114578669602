import React, {useContext, useRef} from 'react';
import {ApplicationContext} from "../../../../../context/applicationContext";
import "./ProjectDetails.css";
import AnimatedContainer from "../../../../../component/animated-container/AnimatedContainer";
import AnimatedImage from "../../../../../component/animated-image/AnimatedImage";
import AnimatedImageSlider from "../../../../../component/animated-image-slider/AnimatedImageSlider";
import QuoteContainer from "../../../../../component/quote-container/QuoteContainer";
import useWindowDimensions from "../../../../../hooks/useWindowDimentions";
import {useNavigate} from "react-router-dom";
import {themeColor} from "../../../../../data/config";

function TropicalShowCase({currentValue}) {
    const {setCurrentFace, setCubeBackgroundColor} = useContext(ApplicationContext);
    const {width} = useWindowDimensions();
    const myRef = useRef(null)
    const myRef2 = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({behavior: "smooth"})
    const executeScroll2 = () => myRef2.current.scrollIntoView({behavior: "smooth"})
    const navigate = useNavigate();

    return (
        <div className="tropical-details-main-container">
            <button style={{
                position: width > 768 ? "fixed" : "absolute",
                top: "2vh",
                right: "5%",
                transform: "rotate(180deg)",
                zIndex: 600
            }}
                    className="link link--leda" data-text="➜"
                    onClick={(e) => {
                        setCubeBackgroundColor(themeColor)
                        setCurrentFace("front")
                        navigate("/projects")
                    }}>
                <span style={{padding: "0 2px", color: "#000"}}>➜</span>
            </button>
            <ul className="tropical-details-nav">
                <li className="tropical-details-nav-li">
                    <button className="tropical-details-nav-button" onClick={executeScroll2}>
                        <span>01</span>ERP System
                    </button>
                </li>
                <li className="tropical-details-nav-li">
                    <button className="tropical-details-nav-button" onClick={executeScroll}>
                        <span>02</span>Portfolio
                    </button>
                </li>
            </ul>

            <AnimatedImage src="/portfolio/tropical/cover.webp" title={"Tropical\nFor\nLandscape"}
                           currentValue={currentValue}/>
            <AnimatedContainer
                textLeft1="Tropical For Landscape"
                textLeft2="Tropical for Contracting and Supplying Co. adopts a clear vision to be one of the pioneering establishments in the field of landscape in Egypt."
                textRight1="The company performs landscape works in commercial, industrial, and residential projects. In order to meet all of the customer's satisfaction, the company adopts the technological state of the art."
                textRight2="With simplicity, flexibility, and maintainability in mind I built a Full-scale ERP System & a Portfolio website."
                isText1Bold
                link="https://tropical-co.com/"
                title="Tropical"
                type="Full Stack Developer"
                isThirdContainer
            />
            <AnimatedImageSlider
                myRef={myRef2}
                images={[{original: "/portfolio/tropical/desktop-7.webp", alt: "ERP"}]}
                title="The Solution:"
                text={["Full-scale ERP System"]}
            />

            <AnimatedContainer
                textLeft1="The Problem was that"
                textLeft2="The company had no online presence."
                textRight1="The company's internal affairs(client relations, human resources, projects, finance, imports, exports, marketing, and storage) were managed by unconnected excel sheets, which lead to a lack of decision-guiding information, loss of data, and corruption."
                isText1Bold
            />

            <AnimatedImageSlider
                images={[{original: "/portfolio/tropical/vid-3.mp4", alt: "Dynamic Form"}]}
                title="Complex forms"
                text={["— Text input",
                    "— Text area input",
                    "— File input",
                    "— Date",
                    "— Checkbox",
                    "— Expandable"]} isVideo/>

            <QuoteContainer
                text1="Finding someone that can not only bring expertise and technical ability but also creativity to enhance the experience is hard to find. But Mostafa does just that."
                text2="When we work with him, we’re not just handing off a direction and waiting for him to implement it. We collaborate throughout the process and the end result is always better than what we initially thought was possible."
                text3="On top of that, he’s an awesome dude, good communicator and fun to be around."
                person="— Abdelrahman, CEO,Tropical"/>

            <AnimatedImageSlider
                images={[{original: "/portfolio/tropical/vid-2.mp4", alt: "Dynamic Form"}]}
                title="Action menu with checkbox"
                text={["Actions can be made on multiple columns all columns or a single column with ease."]} isVideo/>

            <AnimatedImageSlider
                images={[{original: "/portfolio/tropical/desktop-6.webp"}, {original: "/portfolio/tropical/desktop-9.webp"}]}
                style={{margin: "2% auto 0 auto"}} isText={false}
            />
            <AnimatedContainer
                style={{margin: "0 0 2% 0"}}
                textLeft2="Filters"
                textLeft1="Permission system for each user"
                textRight2="Search in the system is implemented in such a way that, every filter is stand-alone eg. you can find all the projects which have a status of under construction and in a location named the new capital."
                textRight1="The ERP system had multiple users each with his own permissions to read and write to each category in the system eg. a user can have access to the CRM to only read the client's data but not edit or add new clients"
                isLeftSideBold
            />
            <AnimatedImageSlider myRef={myRef}
                                 images={[{original: "/portfolio/tropical/main.webp", alt: "ERP"}]}
                                 title="Portfolio Website"
                                 text={[""]}/>
            <AnimatedContainer style={{marginTop: "2%"}}
                               textLeft1="100% controlled by the ERP system"
                               textLeft2="Has a section for services done by the company"
                               textRight1="Has a section for the company's current and recent projects"
                               textRight2="Detailed about us page"
            />
            <AnimatedImageSlider
                title="Portfolio Website"
                images={[
                    {original: "/portfolio/tropical/desktop-4.webp"},
                    {original: "/portfolio/tropical/desktop-3.webp"},
                    {original: "/portfolio/tropical/desktop-2.webp"},
                    {original: "/portfolio/tropical/desktop-1.webp"},]}
                style={{margin: "2% auto 0 auto"}}
                text={["— Services",
                    "— Projects",
                    "Projects are filterable by type",
                    "(construction, maintenance, private villas)",
                    "— Project detailed view",
                    "— History Of The Company"]}
            />
            <AnimatedImageSlider
                images={[{original: "/portfolio/tropical/vid-1.mp4", alt: "Dynamic Form"}]}
                title="Motion"
                text={["I adopted a philosophy of light motion in the website, sliding effects, and fade effects which give a natural pleasant feeling to the overall experience."]}
                isVideo/>
            <div className="tropical-details-next-project">
                <h2 className="tropical-details-next-project-h2">next project</h2>
                <span className="tropical-details-next-project-span" onClick={(e) => {
                    setCurrentFace("left");
                    setCubeBackgroundColor("#000")
                    navigate("/projects/math-masr")
                }}>Math<br/>Masr</span>
            </div>
        </div>
    );
}

export default TropicalShowCase;