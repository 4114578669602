import React, {useContext, useEffect, useState} from 'react';
import {motion} from 'framer-motion';
import {Button} from "../../../common";
import MoreDetailsPage from "./portoflio/MoreDetailsPage";
import "./portoflio/carousel.css";
import {ApplicationContext} from "../../../context/applicationContext";
import {CubeTransition} from "./portoflio/cube-animation";
import TropicalShowCase from "./portoflio/tropical-details/TropicalShowCase";
import MathMasrShowCase from "./portoflio/math-masr-details/MathMasrShowCase";
import {useLocation, useNavigate} from "react-router-dom";
import {themeColor} from "../../../data/config";

function FixedSizeModal({data}) {
    const [isOpen, setIsOpen] = useState(false);

    const {currentFace, setCurrentFace, cubeBackgroundColor, setCubeBackgroundColor} = useContext(ApplicationContext);

    const navigate = useNavigate();
    const location = useLocation();

    const handleOpen = () => {
        setIsOpen(true)
        navigate("/projects")
    };
    const handleClose = () => {
        setIsOpen(false);
        setCurrentFace("front")
        setCubeBackgroundColor(themeColor)
        navigate("/")
    };

    const modalVariants = {
        open: {
            scale: 1,
            opacity: 1,
        },
        closed: {
            scale: 0,
            opacity: 0,
        },
    };

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isOpen]);

    function handleButtonClick(face) {
        setCurrentFace(face);
    }

    useEffect(() => {
        console.log('Current path:', location.pathname);
        switch (location.pathname) {
            case "/":
                setIsOpen(false)
                handleButtonClick("front");
                setCubeBackgroundColor(themeColor)
                break
            case "/projects":
                setIsOpen(true)
                handleButtonClick("front");
                setCubeBackgroundColor(themeColor)
                break
            case "/projects/math-masr":
                setIsOpen(true)
                setTimeout(() => {
                    handleButtonClick("left");
                    setCubeBackgroundColor("#000")
                }, 1300);
                break
            case "/projects/tropical":
                setIsOpen(true)
                setTimeout(() => {
                    handleButtonClick("right")
                    setCubeBackgroundColor("#fff")
                }, 1300);
                break
        }

    }, [location]);

    return (
        <div className="fixed-size-modal">
            <Button onClick={handleOpen}>More</Button>

            {isOpen && (
                <motion.div
                    initial="closed"
                    animate="open"
                    exit="closed"
                    variants={modalVariants}
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 500,
                        background: cubeBackgroundColor
                    }}
                >
                    <motion.div style={{display: "flex", flexDirection: "column"}}>
                        <button className="closeBtn" style={{color: currentFace === "right" ? "#000" : "#F6E6C2"}}
                                onClick={handleClose}>X
                        </button>
                        {/*todo kol project leh na7ya*/}
                        <CubeTransition
                            className="cube-container"
                            face={currentFace}
                            frontCSS={{background: themeColor}}
                            backCSS={{background: themeColor}}
                            rightCSS={{background: "#fff"}}
                            leftCSS={{background: "#000"}}
                            topCSS={{background: themeColor}}
                            bottomCSS={{background: themeColor}}
                            frontPage={<MoreDetailsPage isOpen={isOpen} data={data}/>}
                            backPage={<TropicalShowCase/>}
                            leftPage={<MathMasrShowCase currentValue={"left"}/>}
                            rightPage={<TropicalShowCase currentValue={"right"}/>}
                            topPage={<TropicalShowCase/>}
                            bottomPage={<TropicalShowCase/>}
                            contentElevation={0}
                        />
                        {/*<MoreDetailsPage isOpen={isOpen} data={data}/>*/}
                    </motion.div>
                </motion.div>
            )}
        </div>
    );
}

export default FixedSizeModal;