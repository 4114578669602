import {Container} from '../../common';
import {Grid, ProjectWrapper, Wrapper} from './styles';

import React from 'react';
import Card from "../../component/card/Card";
import FixedSizeModal from "./modal/FixedSizeModal";
import portfolioData from "./portfolioData.json"

export const Projects = () => {

    return (
        <Wrapper id="projects">
            <h2>Projects</h2>
            <ProjectWrapper as={Container}>
                <Grid>
                    {
                        portfolioData.map((item, index) => {
                            if (index!==0 && index < 4)
                                return <Card
                                    imageSrc={item.images.logo}
                                    title={item.title}
                                    text2={item.description}
                                    buttonText="Link"
                                    link={portfolioData[index].link}
                                    detailsLink={item.detailsLink}
                                />
                        })
                    }
                </Grid>
                <FixedSizeModal data={portfolioData}/>
            </ProjectWrapper>
        </Wrapper>
    );
};
