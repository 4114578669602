import {Card, Container} from '../../common';
import {Content, Grid, Stats, Wrapper} from './styles';

import React from 'react';

export const Stars = () => {

	return (
		<Wrapper as={Container} id="Stars">
			<h2>Recently Starred</h2>
			<Grid>
				{/*{nodes.map(node => (*/}
				{/*	<Card key={node.id} style={{cursor: 'pointer'}} onClick={() => window.open(node.url, '_blank')}>*/}
				{/*		<Content data-cy="content">*/}
				{/*			<h4>{node.name}</h4>*/}
				{/*			<p>{node.description}</p>*/}
				{/*		</Content>*/}
				{/*		<Stats>*/}
				{/*			<div>*/}
				{/*				<img src={starIcon} alt="stars"/>*/}
				{/*				<span>{node.stargazers.totalCount}</span>*/}
				{/*			</div>*/}
				{/*			<div>*/}
				{/*				<img src={forkIcon} alt="forks"/>*/}
				{/*				<span>{node.forkCount}</span>*/}
				{/*			</div>*/}
				{/*		</Stats>*/}
				{/*	</Card>*/}
				{/*))}*/}
			</Grid>
		</Wrapper>
	);
};
