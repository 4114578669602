import React from "react";
import "./Card.css";
import {useInView} from 'react-intersection-observer';
import {Button} from "../../common";
import {useNavigate} from "react-router-dom";

const Card = ({imageSrc, title, text2, buttonText, link = "", detailsLink = ""}) => {
    const {ref, inView} = useInView({
        triggerOnce: false, // only trigger the animation once
    });

    const navigate = useNavigate();

    return (
        <div ref={ref} className={`card ${inView ? 'animate-in' : 'animate-out'}`}>
            <div className="card__image-container">
                <img src={imageSrc} alt={title} className="card__image"/>
            </div>
            <div className="card__content">
                <h3 className="card__title">{title}</h3>
                <p className="card__text">{text2}</p>
                <Button className="card__button" onClick={() => window.open(link, '_blank')}>{buttonText}</Button>
                {
                    detailsLink !== "" &&
                    <Button className="card__button" onClick={() =>
                        navigate(detailsLink)
                    }>More Details</Button>
                }
            </div>
        </div>
    );
};

export default Card;