import React, {useEffect, useState} from 'react';
import {useInView} from "react-intersection-observer";
import "./AnimatedContainer.css"

function AnimatedContainer({
                               textLeft1 = "",
                               textLeft2 = "",
                               textRight1 = "",
                               textRight2 = "",
                               isText1Bold = false,
                               isLeftSideBold = false,
                               isThirdContainer = false,
                               link = "",
                               title = "",
                               type = "",
                               style,
                               themeColor = "#000",
                               myRef,
                               handleOnClick,
                               buttonTxt
                           }) {
    const [delayedInView, setDelayedInView] = useState(false);
    const [delayedInView2, setDelayedInView2] = useState(false);
    const {ref, inView} = useInView({triggerOnce: false});

    useEffect(() => {
        setTimeout(() => setDelayedInView(inView), 200);
        setTimeout(() => setDelayedInView2(inView), 400);
    }, [inView]);
    let pStyle = isText1Bold || isLeftSideBold ?
        {
            fontWeight: "bold",
            fontSize: 27
        }
        :
        {
            fontWeight: "normal",
            fontSize: 18
        }
    let divStyle = isLeftSideBold ?
        {
            fontWeight: "bold",
            fontSize: 27
        }
        :
        {
            fontWeight: "normal",
            fontSize: 18
        }
    return (
        <div ref={ref} style={style} className="animated-container-container">
            <div ref={myRef} className={`animated-container ${inView ? 'fade-up' : 'fade-out'}`}>
                <div style={divStyle}>
                    <p style={pStyle}>
                        {textLeft1}
                    </p>
                    <p>
                        {textLeft2}
                    </p>
                </div>

            </div>
            <div className={`animated-container ${delayedInView ? 'fade-up' : 'fade-out'}`}>
                <div>
                    <p>
                        {textRight1}
                        <button className="animated-container-btn" style={{color: themeColor}}
                                onClick={handleOnClick}>{buttonTxt}</button>
                    </p>
                    <p>
                        {textRight2}
                    </p>
                </div>
            </div>
            <div style={{display: isThirdContainer ? "block" : "none"}}
                 className={`animated-container ${delayedInView2 ? 'fade-up' : 'fade-out'}`}>
                <div>
                    <a href={link} target="_blank" style={{fontWeight: "bold", color: themeColor}}>{link}</a>
                    <p>
                        {title}
                    </p>
                    <p><strong>My Role:</strong><br/>{type}</p>
                </div>
            </div>
        </div>
    );
}

export default AnimatedContainer;