import React, {useEffect, useRef, useState} from 'react';
import './carousel.css';
import {Carousel} from "./carousel/MyCarousel";
import DetailedCard from "./detailed_card/DetailedCard";
import useWindowDimensions from "../../../../hooks/useWindowDimentions";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination, Thumbs} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";

function MoreDetailsPage({isOpen, data}) {

    const [slides, setSlides] = useState([]);
    const [page, setPage] = useState(2);
    const swiperRef = useRef();
    const {width} = useWindowDimensions();

    const callback = function (index) {
        setPage(index)
    }

    useEffect(() => {
        let tempSlides = []
        for (let i = 0; i < data.length; i++) {
            let code;
            if (width > 1400) {
                code = <img style={{width: "100%", objectFit: "cover",}} src={`${data[i].images.main}`}
                            alt={`${data[i].images.alt}`}/>;
            }
            else if (width > 768) {
                code = <img style={{width: "100%", objectFit: "cover",height: "48vh"}} src={`${data[i].images.main}`}
                            alt={`${data[i].images.alt}`}/>;
            }
            else {
                code = <img src={`${data[i].images.mainMobile}`} alt={`${data[i].images.alt}`}/>;
            }
            tempSlides.push(code)
        }
        setSlides(tempSlides)
    }, [data]);


    return (
        <div>
            {
                width > 768 ?
                    <Carousel slides={slides} onSlideChange={callback}/>
                    :
                    <Swiper
                        style={{
                            width: "100%",
                            marginTop: "4%"
                        }} slidesPerView={1} speed={1000} modules={[Pagination, Navigation, Thumbs]}
                        watchSlidesProgress navigation={true}
                        onBeforeInit={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                        onSlideChange={(swiper) => setPage(swiper.activeIndex)}>
                        {slides.map((slide, index) => {
                            return (
                                <SwiperSlide id={index}>{slide}</SwiperSlide>
                            )
                        })}
                    </Swiper>
            }
            <DetailedCard isOpen={isOpen} data={data[page]}/>
        </div>
    );
}

export default MoreDetailsPage;