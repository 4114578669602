import {Details, Thumbnail, Wrapper} from './styles';

import ContactForm from './ContactForm';
import {Container} from '../../common';
import React from 'react';

export const Contact = () => (
	<Wrapper as={Container} id="contact">
		<Details>
			<ContactForm/>
		</Details>
		<Thumbnail>
			<img src="/illustrations/contact.svg" alt="Two people talking."/>
		</Thumbnail>
	</Wrapper>
);
