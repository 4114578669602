import React, {useContext, useEffect, useState} from 'react';
import "./DetailedCard.css";
import {ApplicationContext} from "../../../../../context/applicationContext";
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "../../../../../hooks/useWindowDimentions";

const DetailedCard = ({isOpen, data}) => {
    const [delayedOpen, setDelayedOpen] = useState(false);
    const [isAnimated, setIsAnimated] = useState(false);
    const navigate = useNavigate();

    const {setCurrentFace, setCubeBackgroundColor} = useContext(ApplicationContext);
    const {width} = useWindowDimensions();

    useEffect(() => {
        setTimeout(() => setDelayedOpen(isOpen), 1200);
    }, [isOpen]);

    useEffect(() => {
        setIsAnimated(true);
        setTimeout(() => setIsAnimated(false), 1200);
    }, [data]);

    function handleButtonClick(face) {
        setCurrentFace(face);
    }

    return (
        <div className={`detailed-card ${delayedOpen ? "scale-in" : "scale-out"}`}>
            <div className="detailed-card-header">
                <h2 className={`detailed-card-title ${isAnimated ? 'animated' : ''}`}><a href={data.link} target="_blank">{data.title}
                </a></h2>
            <p className={width<768? "d-none" :"detailed-card-date"}>{data.finishDate}</p>
                {data.isMoreDetails && <button onClick={(e) => {
                        switch (data.title) {
                            case "Math Masr":
                                handleButtonClick("left")
                                setCubeBackgroundColor("#000")
                                navigate("/projects/math-masr")
                                break
                            case "Tropical For Landscape":
                                handleButtonClick("right")
                                setCubeBackgroundColor("#fff")
                                navigate("/projects/tropical")
                                break
                        }
                    }} className={width<768? "d-none" :`link link--dia moreBtn ${isAnimated ? 'animated' : ''}`}>
                        SHOW CASE
                    </button>
                }

            </div>
            <hr className="solid"/>
            <div className="detailed-card-body">
                <p className={`detailed-card-paragraph ${isAnimated ? 'animated' : ''}`}>{data.description}</p>

            </div>
            <hr className="solid"/>
            <div className="detailed-card-footer">
                <h2>Screenshots:</h2>
                <ul className={`detailed-card-icon-list2 ${isAnimated ? 'animated' : ''}`}>
                    {
                        data.images.screenshots.map((item, index) => {
                            return (
                                <li className="detailed-card-icon-li">
                                    <a href={item} target="_blank">{index + 1}<img style={{alignSelf: "start"}} alt="hr"
                                                                                   src="/icons/external-link.svg"/></a>
                                </li>)
                        })
                    }
                </ul>
            </div>
            <div className="detailed-card-footer">
                <h2>Frameworks & languages:</h2>
                <ul className={`detailed-card-icon-list ${isAnimated ? 'animated' : ''}`}>
                    {
                        data.images.languages.map((item) => {
                            return (
                                <li><img style={{height: 40, maxWidth: 70}} alt={item.name} src={item.link}/></li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    );
};

export default DetailedCard;
